<!-- Version: 1.0 -->
<template>
  <div class="eh-slider-box">
    <span>{{ title }}</span>
    <span v-if="required" class="text-danger">*</span>
    <div>
      <span class="eh-slider-input-description">{{ description }}</span>
    </div>
    <input
      class="eh-slider-input"
      :class="setClass"
      type="range"
      v-model="tempValue"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
    />
    {{ tempDisplayType == $enums.DisplayType.Vertical ? tempValue : "" }}
    <div
      v-if="tempDisplayType == $enums.DisplayType.Horizontal"
      style="text-align: center"
    >
      {{ tempValue }}
    </div>
  </div>
</template>
<script>
export default {
  name: "fp-slider",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    displayType: { type: String },
    min: Number,
    max: Number,
    step: Number,
    value: [Number, String],
    disabled: Boolean,
    defaultValue: [Number, String],
    title: String,
    description: String,
    required: Boolean,
  },
  data() {
    return {
      tempValue: this.value
        ? this.value
        : this.defaultValue
        ? this.defaultValue
        : 0,
      tempDisplayType: this.displayType
        ? this.displayType
        : this.$enums.DisplayType.Horizontal,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.tempValue = input;
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.tempDisplayType == this.$enums.DisplayType.Vertical) {
        temp += " vertical-slider ";
      } else {
        temp += " horizontal-slider ";
      }
      if (this.disabled) {
        temp += " disabled-input ";
      } else {
        temp += " not-isabled-input ";
      }
      return temp;
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.$emit("change", this.tempValue);
    }
  },
};
</script>
<style scoped>
.slider-box {
  width: 100%;
  text-align: left;
}
.input-slider-description {
  font-size: 14px;
  color: #00000099;
}
.input-slider.vertical {
  -webkit-appearance: none;
  width: 200px;
  margin: 100px -85px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  z-index: 0;
}
.input-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #cce1f0;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border: solid 3px #627e8f;
  border-radius: 10px;
  overflow: hidden;
}

.input-slider:hover {
  opacity: 1;
}

.input-slider.input-disabled:hover {
  opacity: 0.8;
}

.input-disabled.input-slider {
  background: #cce1f077;
  border: solid 3px #627e8f77;
}
.input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 19px;
  background: #84a9c1;
  border: solid 1px #627e8f99;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: -100vw 0 0 99vw orange;
}

.input-slider.input-disabled::-webkit-slider-thumb {
  cursor: default;
  background: #84a9c177;
  border: solid 1px #627e8f77;
  box-shadow: -100vw 0 0 99vw #ffa50077;
}

.input-slider.input-disabled::-moz-range-thumb {
  cursor: default;
  background: #84a9c177;
  border: solid 1px #627e8f77;
  box-shadow: -100vw 0 0 99vw #ffa50077;
}

.input-slider::-moz-range-thumb {
  width: 20px;
  height: 17px;
  background: #84a9c1;
  border: solid 1px #627e8f99;
  cursor: pointer;
  box-shadow: -100vw 0 0 99vw orange;
}

/* input[type="range"]::-moz-range-progress,
input[type="range"]::-webkit-slider-runnable-track{
  height: 19px;
  border-radius: 7px 0 0 7px;
  background-color: orange;
} */
</style>
